import { changeLocale, IntlContextConsumer } from 'gatsby-plugin-intl';
import React from 'react';
import * as styles from './language.module.scss';
import T from './T';

interface IntlContextConsumerParams {
  languages: string[];
  language: string;
}

const languageName: { [index: string]: string } = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ro: 'Română',
  pt: 'Português',
};

const localeSelect = ({ languages, language }: IntlContextConsumerParams) => {
  const handleChange = (event: any) => changeLocale(event.target.value);

  return (
    <div className={styles.container}>
      <label htmlFor="language_selector" className={styles.label}>
        <T id="Select your language" />
      </label>
      <select
        value={language}
        onChange={handleChange}
        className={styles.selectCss}
        id="language_selector"
      >
        {languages.map((l) => (
          <option key={l} value={l}>
            {languageName[l]}
          </option>
        ))}
      </select>
    </div>
  );
};

const Language = () => {
  return <IntlContextConsumer>{localeSelect}</IntlContextConsumer>;
};

export default Language;
