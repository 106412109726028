import React from 'react';

import {
  createHypersayClient,
  HypersayProvider,
  Login,
} from '@hypersay/toolkit';
// tslint:disable-next-line: no-submodule-imports
import { TranslateFn } from '@hypersay/toolkit/dist/utils/translate';
import { Modal, useDetectTheme } from '@hypersay/ui';
// tslint:disable-next-line: no-submodule-imports
import { Theme } from '@hypersay/ui/dist/components/globals';
import { useIntl } from 'gatsby-plugin-intl';
import { DialogStateReturn } from 'reakit';
import { sendFeature } from '../util/ga';
import { getAppSiteUrl } from '../util/navigate-to-app-site';

interface LoginModalProps {
  trigger?: any;
  onLoggedIn?: () => unknown;
}

if (!process.env.HYPERSAY_API_ENDPOINT) {
  throw new Error('HYPERSAY_API_ENDPOINT env must be set');
}
if (!process.env.HYPERSAY_BROKER_URL) {
  throw new Error('HYPERSAY_BROKER_URL env must be set');
}
if (!process.env.HYPERSAY_LEGACY_API_ENDPOINT) {
  throw new Error('HYPERSAY_LEGACY_API_ENDPOINT env must be set');
}

const hypersayClient = createHypersayClient({
  brokerURL: process.env.HYPERSAY_BROKER_URL,
  legacyApiUrl: process.env.HYPERSAY_LEGACY_API_ENDPOINT,
  apiEndpoint: process.env.HYPERSAY_API_ENDPOINT,
  authTokenName: process.env.HYPERSAY_AUTH_TOKEN_NAME,
});

const LoginModal: React.FC<LoginModalProps &
  React.HTMLAttributes<HTMLDivElement>> = ({
    trigger,
    onLoggedIn = () => {
      window.location.href = getAppSiteUrl();
    },
    ...props
  }) => {
  const { formatMessage } = useIntl();
  const t: TranslateFn = (text, replacements) => {
    return formatMessage(
      { id: `TOOLKIT:${text}`, defaultMessage: text },
      replacements,
    );
  };
  const detectedTheme = useDetectTheme();

  return (
    <Modal
      trigger={trigger ? trigger : 'Login'}
      showCloseButton={false}
      style={{ padding: '0' }}
      theme={detectedTheme as Theme}
      {...props}
    >
      {(dialog: DialogStateReturn) => {
        if (dialog.visible) {
          sendFeature('LoginModal', 'visible');
        }
        return dialog.visible ? (
          <HypersayProvider
            client={hypersayClient}
            autoConnect
            translate={{ t }}
          >
            <Login
              onCancel={dialog.hide}
              onLoggedIn={onLoggedIn}
              theme={detectedTheme as Theme}
            />
          </HypersayProvider>
        ) : null;
      }}
    </Modal>
  );
};

export default LoginModal;
