import { GatsbyLinkProps } from 'gatsby';
import { Link as OriginalLink } from 'gatsby-plugin-intl';
import * as React from 'react';

export interface LinkProps extends GatsbyLinkProps<{}> {
  track?: string;
}

const createFunctionWithTimeout = (
  callback: (event: any) => void,
  optTimeout?: number,
) => {
  let called = false;
  const raceCallback = (event: any) => {
    if (!called) {
      called = true;
      callback(event);
    }
  };

  setTimeout(raceCallback, optTimeout || 100);
  return raceCallback;
};

export const Link: React.FC<LinkProps> = (props) => {
  const { onClick: originalOnClick, track } = props;

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    try {
      // @ts-ignore
      if (typeof window.gtag !== 'undefined') {
        // @ts-ignore
        window.gtag('event', 'click', {
          eventCategory: 'InternalLink',
          eventAction: props.to,
          eventLabel: window.location.pathname,
        });
      }

      if (track) {
        // @ts-ignore
        if (typeof window.gtag !== 'undefined') {
          // @ts-ignore
          window.gtag('event', 'click', {
            eventCategory: 'InternalLinkExtra',
            eventAction: track,
            eventLabel: window.location.pathname,
          });
        }
      }
    } catch (e) {
      // do nothing
    }

    if (originalOnClick) {
      const callAtLeastOnce = createFunctionWithTimeout(originalOnClick, 200);
      callAtLeastOnce(event);
    }
  };

  // @ts-ignore
  return <OriginalLink {...props} onClick={onClick} />;
};

export default { Link };
