import { sendAndGo } from './ga';
export const getCurrentWebsiteUrl = (to?: string) => {
  let path = to;
  if (typeof to === 'string' && to.charAt(0) === '/') {
    path = to.substr(1);
  }
  return `${process.env.HYPERSAY_WEBSITE_URL}/${path || ''}`;
};

/**
 * Create an url to Hypersay live page (e.g. live.hypersay.com)
 * @param to No initial slash path (e.g. `s/ACODE`)
 */
export const getLiveSiteUrl = (to?: string) => {
  let path = to;

  if (typeof to === 'string' && to.charAt(0) === '/') {
    path = to.substr(1);
  }
  return `${process.env.HYPERSAY_FRONTEND_LIVE_APP}/${path || ''}`;
};

/**
 * Navigates to the Hypersay live page (e.g. live.hypersay.com)
 * @param to No initial slash path (e.g. `s/ACODE`)
 */
export const navigateToLiveWebsite = (to?: string) =>
  sendAndGo(
    {
      category: 'NavigateToLiveWebsite',
      action: 'navigate',
      label: window.location.href,
    },
    getLiveSiteUrl(to),
  );

/**
 * Create an url to Hypersay application page (e.g. app.hypersay.com)
 * @param to No initial slash path (e.g. `s/ACODE`)
 */
export const getAppSiteUrl = (to?: string) => {
  let path = to;

  if (typeof to === 'string' && to.charAt(0) === '/') {
    path = to.substr(1);
  }
  return `${process.env.HYPERSAY_FRONTEND_APP_API}/${path || ''}`;
};

/**
 * Navigates to the Hypersay application page (e.g. app.hypersay.com)
 * @param to No initial slash path (e.g. `s/ACODE`)
 */
export default function navigateToAppSite(to?: string) {
  sendAndGo(
    {
      category: 'navigateToAppSite',
      action: 'navigate',
      label: window.location.href,
    },
    getAppSiteUrl(to),
  );
}
