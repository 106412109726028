import React from 'react';
import Helmet from 'react-helmet';

import { withPrefix } from 'gatsby';

export const Registered: React.FC<{}> = () => {
  return (
    <Helmet>
      <script
        src={withPrefix('registered.js')}
        type="text/javascript"
        async
      ></script>
    </Helmet>
  );
};
