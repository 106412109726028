import React from 'react';

import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';

interface Meta {
  name: string;
  content: string;
}

interface SEOProps {
  description?: string;
  meta?: Meta[];
  keywords?: string[];
  title: string;
  shareImage?: string;
}

const SEO = ({
  description = 'Hypersay - live interactive presentations',
  meta = [],
  keywords = [],
  title,
  shareImage = 'share-image-presentations.jpg',
}: SEOProps) => {
  const intl = useIntl();
  const lang = intl.locale;
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const mediaCard = withPrefix(`/images/${shareImage}`);
  const mediaImage = `${process.env.HYPERSAY_WEBSITE_URL}${mediaCard}`;
  // const pathname =
  //   typeof window !== 'undefined' ? window.location.pathname : '/';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: 'help',
          href: '/blog/guide',
        },
      ]}
      meta={[
        {
          itemprop: 'api',
          content: process.env.GATSBY_HYPERSAY_GET_USER_ENDPOINT || '',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:site_name',
          content: 'Hypersay',
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          property: 'og:type',
          content: 'product',
        },
        // {
        //   property: 'og:url',
        //   content: `https://hypersay.com${path}`,
        // },
        {
          property: 'og:image',
          content: mediaImage,
        },
        {
          property: 'fb:app_id',
          content: '143554972836086',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        // {
        //   name: 'twitter:url',
        //   content: `https://hypersay.com${path}`,
        // },
        {
          name: 'twitter:site',
          content: '@hyper_say',
        },
        {
          name: 'twitter:image',
          content: mediaImage,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : [],
        )
        .concat(meta)}
    />
  );
};

export default SEO;
