export interface GAEvent {
  category: string;
  action: string;
  label?: string;
}

export const pageView = () => {
  const w = window as any;
  if (typeof w.ga === 'function') {
    try {
      w.ga('send', 'pageview');
    } catch (e) {
      console.error('Could not send pageview :', e);  
    }
  }
};

export const send = (evt: GAEvent) => {
  const w = window as any;
  if (typeof w.ga === 'function') {
    try {
      w.ga('send', 'event', {
        eventCategory: evt.category,
        eventAction: evt.action,
        eventLabel: evt,
      });
    } catch (e) {
      console.error('Could not send event:', e, evt);
    }
  }
};

/**
 * Sends an event to Google Analytics in order to track some feature usage
 * @param feature is the feature you want to track
 *        (e.g. PricingButton, ContactUs, etc.)
 * @param action if present can be a boolean to track if the entity
 *          was enabled/disabled, open/closed. If string, it is tracked as such
 */
export const sendFeature = (feature: string, action?: string | boolean) => {
  send({
    category: `${feature}Feature`,
    action: action === undefined
      ? 'used'
      : (typeof action === 'boolean'
          ? (action ? 'enabled' : 'disabled')
          : action
      ),
  });
};

export const sendAndGo = (evt: GAEvent, href: string) => {
  const w = window as any;
  if (typeof w.ga === 'function') {
    // Just in case the w.ga() fails, go anyway
    setTimeout(() => {
      window.location.href = href;
    }, 500);

    try {
      send(evt);
      w.ga(() => {
        window.location.href = href;
      });
    } catch (e) {
      window.location.href = href;
    }
  } else {
    window.location.href = href;
  }
};
