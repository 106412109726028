import { FormattedHTMLMessage, FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

// Wraps react-intl components
// The default is to use `FormattedMessage` because documentation discourage from using
// `FormattedHTMLMessage` as explained at
// https://https://github.com/formatjs/react-intl/blob/master/docs/Components.md

interface TProps {
  forceHtml?: boolean;
  id: string;
  tagName?: string;
  values?: {};
}

export default ({ forceHtml = false, tagName, values, id, ...props }: TProps) =>
  forceHtml ? (
    <FormattedHTMLMessage
      id={id}
      values={values}
      defaultMessage={id}
      tagName={tagName}
      {...props}
    />
  ) : (
    <FormattedMessage
      id={id}
      values={values}
      tagName={tagName}
      defaultMessage={id}
      {...props}
    />
  );
