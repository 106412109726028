import * as React from 'react';
import { ContainerFullWidth, ContainerMaxWidth, Stack } from '@hypersay/ui';

import { Link } from './Link';

import Language from './language';
import T from './T';

import './footer.scss';

const Footer = () => (
  <ContainerFullWidth className="hs-footer-container">
    <ContainerMaxWidth>
      <div className="footer-grid">
        <div>
          <Stack direction="vertical" gap=".5em" className="infoLanguageBox">
            <div className="info">
              <div className="productby">
                <T id="Hypersay is a product by Learn Forward" />
              </div>
              {/* <div>71 Queen Victoria Street, London</div> */}
              <a
                href="mailto:hello@hypersay.com"
                className="link"
                rel="noopener noreferrer"
              >
                hello@hypersay.com
              </a>
            </div>
            <Language />
          </Stack>
        </div>
        <div>
          <Stack direction="vertical" gap=".5em">
            <T id="How to Hypersay" />
            <Link className="link" to="/blog/education/">
              <T id="Education" />
            </Link>
            <Link className="link" to="/blog/events/">
              <T id="Events & conf" />
            </Link>
            <Link className="link" to="/blog/business/">
              <T id="Business" />
            </Link>
            <Link className="link" to="/blog/work-remotely/">
              <T id="Work remotely" />
            </Link>
          </Stack>
        </div>
        <div>
          <Stack direction="vertical" gap=".5em">
            <T id="Terms & conditions" />
            <Link className="link" to="/terms/">
              <T id="Terms of Use" />
            </Link>
            <Link className="link" to="/privacy/">
              <T id="Privacy Policy" />
            </Link>
            <a className="link" href={`${process.env.HYPERSAY_FRONTEND_APP_API}/data-protection/`}>
              <T id="Data Protection and GDPR" />
            </a>
          </Stack>
        </div>
        <div>
          <Stack direction="vertical" gap=".5em">
            <T id="Follow us" />
            <a
              className="link"
              href="https://www.youtube.com/channel/UCBC93qcF3ghV49HwaivBf9g"
              target="_blank"
              rel="noopener noreferrer"
            >
              Youtube
            </a>
            <a
              className="link"
              href="https://facebook.com/hypersay"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              className="link"
              href="https://twitter.com/@hyper_say"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter @hyper_say
            </a>
          </Stack>
        </div>
      </div>
    </ContainerMaxWidth>
  </ContainerFullWidth>
);

export default Footer;
