import React from 'react';

import { Button, Dropdown, Icons, Item, Stack } from '@hypersay/ui';

import { withPrefix } from 'gatsby';

import T from '../components/T';
import { Link } from '../components/Link';

import {
  getAppSiteUrl,
  navigateToLiveWebsite,
  // getCurrentWebsiteUrl,
} from '../util/navigate-to-app-site';

// tslint:disable-next-line: no-submodule-imports
import { Size } from '@hypersay/ui/dist/components/globals';
import * as styles from './header.module.scss';
import LoginModal from './LoginModal';

const HypersayLogo = () => (
  <div className={styles.headerLogo}>
    <div className={styles.blob} />
    <Link to="/">
      <img
        src={withPrefix('/svg/hypersay-logo-spaced3.svg')}
        alt="hypersay logo"
      />
    </Link>
  </div>
);

const joinButton = (size: Size) => (
  <Button
    onClick={() => navigateToLiveWebsite()}
    label={<T id="Join Session" />}
    variant="secondary"
    outline={true}
    size={size}
    theme="dark"
  />
);

const MenuDesktop = () => {
  // const currentUrl = getCurrentWebsiteUrl();
  return (
    <Stack
      direction="horizontal"
      gap="1rem"
      className={`${styles.header} ${styles.forDesktopOnly}`}
    >
      <HypersayLogo />
      <Link to="/live-presentations/" activeClassName={styles.activePage}>
        <Button
          label={<T id="Live Presentations" />}
          variant="glass"
          size="m"
          theme="dark"
        />
      </Link>
      <Link to="/live-subtitles/" activeClassName={styles.activePage}>
        <Button
          label={<T id="Live Subtitles" />}
          variant="glass"
          size="m"
          theme="dark"
        />
      </Link>

      <Link to="/pricing/" activeClassName={styles.activePage}>
        <Button
          label={<T id="Pricing" />}
          variant="glass"
          size="m"
          theme="dark"
        />
      </Link>
      <Link to="/blog/guide/" activeClassName={styles.activePage}>
        <Button label={<T id="Blog" />} variant="glass" size="m" theme="dark" />
      </Link>
      {joinButton('m')}
      <div className="visibleForNotSignedIn">
        <LoginModal
          trigger={
            <Button
              label={<T id="Sign in" />}
              variant="primary"
              size="m"
              theme="dark"
              style={{ minWidth: '127px' }}
            />
          }
        />
      </div>
      <Button
        label={<T id="Dashboard" />}
        variant="primary"
        size="m"
        theme="dark"
        onClick={() => (window.location.href = getAppSiteUrl())}
        className="visibleForSignedIn"
      />

      {/* <span className="hypersayFirstName">
            <span>
              <T id="you" />
            </span>
          </span>
          ! */}
    </Stack>
  );
};

const MenuMobile = () => {
  // const currentUrl = getCurrentWebsiteUrl();
  return (
    <div className={`${styles.header} ${styles.forMobileOnly}`}>
      <HypersayLogo />
      <Stack direction="horizontal" gap=".5rem">
        {joinButton('s')}
        <div className="visibleForNotSignedIn">
          <LoginModal
            trigger={
              <Button
                label={<T id="Sign in" />}
                variant="primary"
                size="s"
                theme="dark"
                style={{ minWidth: '127px' }}
              />
            }
          />
        </div>
        <Button
          label={<T id="Dashboard" />}
          variant="primary"
          size="s"
          theme="dark"
          onClick={() => (window.location.href = getAppSiteUrl())}
          className="visibleForSignedIn"
        />
        <Dropdown
          trigger={
            <Button
              size="s"
              colors={['--splashed-white', '--mouse-gray']}
              icon={<Icons.More size="m" />}
              theme="dark"
              outline={true}
            />
          }
          aria-label="menu"
          items={[
            <Link to="/live-presentations" key="liveprez">
              <Item label={<T id="Live Presentations" />} />
            </Link>,
            <Link to="/live-subtitles" key="livesub">
              <Item label={<T id="Live Subtitles" />} />
            </Link>,
            // <Link to="/hyperspeech" key="speech">
            //   <Item label={<T id="HyperSpeech" />} />
            // </Link>,
            <Link to="/pricing" key="pric">
              <Item label={<T id="Pricing" />} />
            </Link>,
            <Link to="/blog/guide" key="blog">
              <Item label={<T id="Blog" />} />
            </Link>,
            <Link to="/blog/education" key="educ">
              <Item label={<T id="How to: Education" />} />
            </Link>,
            <Link to="/blog/events" key="evnt">
              <Item label={<T id="How to: Events & conf" />} />
            </Link>,
            <Link to="/blog/business" key="corp">
              <Item label={<T id="How to: Business" />} />
            </Link>,
          ]}
        />
      </Stack>
    </div>
  );
};

const Header = () => {
  return (
    <>
      <MenuDesktop />
      <MenuMobile />
    </>
  );
};

export default Header;
