import React, { ReactNode, useEffect } from 'react';

import Footer from '../../components/footer';
import useWindowDimensions from '../../components/getWindowDimensions';
import Header from '../../components/header';

import { ContainerFullWidth, ContainerMainGrid } from '@hypersay/ui';

import { Registered } from '../../components/registered-header';
import { pageView } from '../../util/ga';
import './index.scss';

export interface LayoutProps {
  children: ReactNode;
  className?: string;
}
export const WindowSizeContext = React.createContext({ width: 0, height: 0 });

const Layout = ({ children, className }: LayoutProps) => {
  const { width, height } = useWindowDimensions();
  useEffect(pageView, []);

  return (
    <WindowSizeContext.Provider value={{ width, height }}>
      <ContainerMainGrid
        sticky={true}
        topmenu={<Header />}
        body={
          <ContainerFullWidth className={className}>
            {children}
          </ContainerFullWidth>
        }
        footer={<Footer />}
        className="containerMain"
      />
      <Registered />
    </WindowSizeContext.Provider>
  );
};

export default Layout;
