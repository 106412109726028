import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  if (typeof window === 'undefined') {
    return { width: 1024, height: 800 }; // this are random
  }

  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    if (window) {
      window.addEventListener('resize', handleResize);
    }

    return () => window && window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
